<template>
  <a-modal
    v-model="show"
    centered
    width="700px"
    title="人脸登录"
    :confirm-loading="loading"
    :footer="null"
  >
    <div class="">
      <div class="text-center flex flex-direction-column">
        <div class="text-left">
          <div>解决https不安全策略方法</div>
          <div>浏览器访问，添加IP白名单：<a @click="$common.copyText('chrome://flags/#unsafely-treat-insecure-origin-as-secure')">chrome://flags/#unsafely-treat-insecure-origin-as-secure</a></div>
          <div>应用右键属性->目标后加：<a @click="$common.copyText(`--unsafely-treat-insecure-origin-as-secure=&quot;http://xxx.com&quot;`)">--unsafely-treat-insecure-origin-as-secure='http://xxx.com"</a></div>
        </div>
        <div class="flex align-item-center justify-between">
          <el-image :src="base64" style="width: 320px; height: 320px;"/>
          <video
            id="video"
            class="video"
            width="320"
            height="320"
            style="border-radius: 600px;"
            autoplay="autoplay"></video>
        </div>
        <!--<div v-show="!cameraStatus" style="width: 320px; height: 320px; border-radius: 600px; border: 10px solid rgba(23,86,142,0.5)"></div>-->
        <!-- 拍照按钮 -->
        <div class="mt20">
          <a-button type="primary" v-if="!cameraStatus" @click="connectCameraFn">拍照</a-button>
          <a-button :loading="loading" type="primary" v-else @click="takePhotoFn">确认</a-button>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        show: true,
        loading: false,
        base64: '',
        token: '',
        cameraStatus: false, // 拍照状态， true => 开启；false => 关闭
      }
    },
    watch: {
      value: {
        handler(val) {
          this.show = val
        },
        deep: true,
        immediate: true
      },
      show(val) {
        this.$emit('input', val)
      }
    },
    mounted() {
      // console.log('chrome://flags/#unsafely-treat-insecure-origin-as-secure')
      // console.log('--unsafely-treat-insecure-origin-as-secure="http://xxx.com"')
      // this.getToken()
      setTimeout(() => {
        this.connectCameraFn()
      }, 1000)
    },
    methods: {
      async getToken() {
        try {
          const res = await request({
            url: '/humanFace/getToken',
          })
          console.log('res', res)
          this.token = res
        } finally {

        }
      },
      // 连接摄像头
      connectCameraFn() {
        this.photoAlertStatus = false; // 关闭提示框
        // 区域控制
        const obj = { width: 200, height: 200 };
        // end
        let constraints = {
          video: obj,
          audio: false
        };
        const video = document.getElementById("video");
        // 当前在IE浏览器无法调用摄像头
        try {
          let Promise = navigator.mediaDevices.getUserMedia(constraints);
          Promise.then(MediaStream => {
            video.srcObject = MediaStream;
            window.MediaStreamTrack = MediaStream.getTracks()[0]; // 摄像头对象
            video.play();
            this.cameraStatus = true;
          });
        } catch (err) {
          this.$message.info("浏览器不支持调用摄像头，请更换最新的谷歌浏览器，或者其他最新版")
        }
      },
      // 绘制照片
      async takePhotoFn() {
        try {
          this.loading = true
          // 绘图
          let video = document.getElementById("video");
          let canvas = document.createElement("canvas");
          canvas.width = canvas.height = 320;
          let ctx = canvas.getContext("2d");
          let w = video.offsetWidth;
          let h = video.offsetHeight;
          let y = (canvas.width - h) / 2;
          ctx.drawImage(video, 0, y, w, h);

          let base64 = canvas.toDataURL("image/jpeg", 0.4); // 获取Base64
          console.log(base64)
          this.base64 = base64
          let Blob = this.convertBase64UrlToBlob(base64); // 获取二进制 流文件

          let formData = new FormData();
          formData.append("file", Blob);
          formData.append("data", base64);
          formData.append("token", this.token);
          // 绘图end

          /*const res = await request({
            url: '/humanFace/searchByImage',
            method: 'post',
            data: formData
          })
          if (res && res[0].externalUuid) {
            const r = await request({
              url: '/humanFace/HFSso',
              method: 'get',
              params: {
                externalUuid: res[0].externalUuid
              }
            })
            this.$router.push("/")
          } else {
            this.$message.info('无法识别人脸，暂无信息')
          }*/
        } finally {
          this.loading = false
          // 关闭
          this.cameraStatus = false; // 修改摄像头启动状态
          window.MediaStreamTrack &&
          window.MediaStreamTrack.stop &&
          window.MediaStreamTrack.stop(); // 关闭摄像头
        }
      },
      convertBase64UrlToBlob(urlData) {
        let arr = urlData.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      },
    },

  }
</script>