<template>
  <div class="login">
    <div class="box pr">
      <div class="txt-40 font-w600 text-white ls10">后台管理</div>
      <a-form-model
        v-if="loginType === 1"
        ref="ruleForm"
        class="login-form"
        :model="form"
      >
        <a-form-model-item class="items" prop="account">
          <img src="@/assets/img/zhanghao.png" alt="" class="login-img">
          <a-input v-model="form.account" class="login-item" placeholder="用户名" allow-clear />  
        </a-form-model-item>
        <a-form-model-item class="items" prop="password">
          <img src="@/assets/img/mima.png" alt="" class="login-img">
          <a-input-password
            v-model="form.password"
            type="password"
            showPassword
            class="login-item"
            placeholder="密码"
            allow-clear
            @keyup.enter="handleSubmit" /> 
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            :loading="loading"
            class="login-form-button denglu"
            @click="handleSubmit"
            type="primary"
          >登录</a-button>
        </a-form-model-item>
      </a-form-model>

      <img src="@/assets/img/szzs.png" alt="" class="wper my35" v-if="loginType === 2">
      <a-button
        :loading="loading"
        v-if="loginType === 2"
        type="primary"
        class="login-form-button mb20"
        @click="certificateLogin"
      >数字证书登录</a-button>
      <!--<div class="sz" v-if="loginType === 1" @click="loginType = 2">数字证书登录</div>-->
      <div class="mima" v-if="loginType === 2" @click="loginType = 1">账号密码登录</div>
      <div class="mt10 txt-20 text-right">
        <a-space :size="10">
          <a-tooltip title="人脸登录">
            <a-icon type="camera" class="text-white pointer" @click="showFaceLogin = true" />
          </a-tooltip>
        </a-space>
      </div>
    </div>
    <face-login v-if="showFaceLogin" v-model="showFaceLogin" />
  </div>
</template>
<script>
  import API from '@/api'
  import FaceLogin from "./components/faceLogin";
  export default {
    components: {FaceLogin},
    data() {
      return {
        showFaceLogin: false,
        loginType: 1,
        form: {
          account: 'admin',
          password: '123456'
        },
        rules: {
          account: [
            { required: true, message: '请输入账号', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' }
          ]
        },
        loading: false
      }
    },
    created() {
      if (this.$route.query.token) {
        const token = this.$route.query.token;
        this.certificateUp(token);
      }
    },
    methods: {
      // 单点登录
      certificateUp(token) {
        this.$cookies.set(token);
        this.$store.dispatch('GetInfo').then(() => { // 拉取用户信息
          this.$store.dispatch('GetMenus')
          this.$router.push({ path:'/' });
        })
      },
      // 数字证书登录
      async certificateLogin() {
        const res = await this.$request({
          url: API.login.szzs,
          method: 'get',
          params: {
            mpCode: "qbsz"
          }
        })
        console.log('证书登录',res);
        window.open(res, "_self");
      },
      async handleSubmit() {
        try {
          this.loading = true
          // await this.$refs.ruleForm.validate()
          if (!this.form.account || !this.form.password) {
            return this.$message.info('请输入账号密码')
          }
          // const res = await this.$request({
          //   url: API.login.Login,
          //   method: 'post',
          //   data: this.form
          // })
          this.$cookies.set(1)
          await this.$store.commit('SET_USERNAME','管理员');
          // await this.$store.dispatch('GetInfo');
          // await this.$store.dispatch('GetMenus');
          await this.$router.push({ path:'/' });
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
.login{
  width: 100%;
  height: 100vh;
  background-size:100% 100%;
  background-image: url(~@/assets/img/login_bg.png);
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
  .box{
    width:576px;
    height: 604px;
    text-align: center;
    background-size:100% 100%;
    background-image: url(~@/assets/img/login_kuang.png);
    background-repeat: no-repeat;
    margin: 0 auto;
    padding: 40px 98px 0 98px;
    box-sizing: border-box;
    .titleImg {
      height: 80px;
      margin: 60px 0 auto;
    }
    .login-form {
      margin-top: 52px;
      .items {
        border-bottom: 1px solid #50A8F7;;
        padding: 4px 0 30px;
      }
      .login-img{
        width: 20px;
        height: 20px;
        margin-top: 4px;
      }
      .login-item {
       border-color: transparent;
       vertical-align: middle;
       color: #fff;
       background-color:rgba(0,0,0,0) !important;
      }
    }
    .login-form-button {
      width: 380px;
      height: 60px;
      opacity: 0.9;
      background-image: linear-gradient(161deg, #51A9F7 0%, #2870ED 90%);
      box-shadow: 0 0 10px 0 #00000026;
      font-weight: 400;
      font-size: 24px;
      color: #fff;
      text-align: center;
      margin-top: 16px;
    }
    .denglu {
      margin-top: 50px;
    }
    .wt {
      width: 340px;
      height: 184px;
      margin: 65px 0 10px 12px;
    }
    .mima {
      text-align: right;
      font-weight: 400;
      font-size: 20px;
      color: #50A8F7;
      margin-top: 14px;
      cursor: pointer;
    }
    .sz {
      text-align: right;
      font-weight: 400;
      font-size: 20px;
      color: #50A8F7;
      margin-top: 14px;
      cursor: pointer;
    }
  }
}
/deep/ .ant-form-item-children {
  display: flex;
}
/deep/ .ant-form-explain {
  // position: absolute;
  // right: 0;
  // margin-bottom: 12px;
  float: right;
}
/deep/ .ant-form-item-with-help {
  margin-bottom: 0 !important;
}
/deep/ .ant-input {
  -webkit-text-fill-color: #ededed !important;  //这个地方的颜色是字体颜色，可以根据实际情况修改
  -webkit-box-shadow: 0 0 0px 1000px transparent  inset !important;  //设置input输入框的背景颜色为透明色
  background-color:transparent !important;  //设置input输入框的背景颜色为透明色
  background-image: none;
  transition: background-color 50000s ease-in-out 0s;
  border: none;
  color: #fff;
  caret-color: #ffffff;
}
  ::v-deep .ant-input-clear-icon, ::v-deep .ant-input-password-icon {
    color: #ffffff;
  }
</style>